import { t } from '@/utility/localization';
import { useState, useEffect } from 'react';
import SectionFolderItem from './SectionFolderItem';
import { getFirstNSections } from './utils';
import NPLButton from '@/components/npl/NPLButton';

const MAXIMUMFOLDERTHRESHOLD = 5;

const ContentCard = ({
  folderItems,
  ctaOnClick,
  hasAccess,
  communitySlug,
  productSlug
}) => {
  const [folderStructure, setFolderStructure] = useState([]);
  const [seeMore, setSeeMore] = useState(false);

  const hasFileFolderItems = folderItems?.some(
    (item) => item?.type !== 'section'
  );
  const toggleShowMore = () => setSeeMore(!seeMore);

  useEffect(() => {
    if (hasFileFolderItems) {
      const folderItemsWithoutSections = folderItems.filter(
        (item) => item.type !== 'section'
      );
      const folderItemsWithSections = folderItems.filter(
        (item) => item.type === 'section'
      );
      setFolderStructure([
        ...folderItemsWithSections,
        { items: folderItemsWithoutSections, title: '', description: '' }
      ]);
    } else {
      setFolderStructure(folderItems);
    }
  }, [folderItems, hasFileFolderItems]);

  const { result: showLessFolderItemsResults, isMoreDataAvailable } =
    getFirstNSections(folderStructure, MAXIMUMFOLDERTHRESHOLD);

  const items = seeMore ? folderStructure : showLessFolderItemsResults;

  return (
    <div className="space-y-24">
      {items.map((folder, idx) => {
        return (
          <div
            key={idx}
            className="flex w-full flex-col gap-24  rounded-3xl bg-npl-neutral-light-solid-2">
            <SectionFolderItem
              ctaOnClick={ctaOnClick}
              hasAccess={hasAccess}
              folderItem={folder}
              defaultExpanded={idx === 0}
              communitySlug={communitySlug}
              productSlug={productSlug}
            />
          </div>
        );
      })}
      {isMoreDataAvailable && (
        <div className="">
          <NPLButton
            onClick={toggleShowMore}
            buttonText={
              seeMore ? t('see-less-sections') : t('see-more-sections')
            }
            stretch={true}
            customClassNames="p-0"
            hierarchy="outline"
          />
        </div>
      )}
    </div>
  );
};
export default ContentCard;
