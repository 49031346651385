import React from 'react';
import LocalBlurredBackground from '@/pages/portal/products/components/LocalBlurredBackground/LocalBlurredBackground';
import { t } from '@/utility/localization';
import Icon, { IconNameWithPath } from '@/components/npl/Icon';

type MembershipBenefit = {
  title: string;
} & IconNameWithPath<'common'>;
type MembershipBenefitsProps = {
  parentThumbnail: string;
  membershipBenefits: MembershipBenefit[];
};

const MembershipBenefits = ({
  parentThumbnail,
  membershipBenefits
}: MembershipBenefitsProps) => {
  return (
    <div className="relative">
      <LocalBlurredBackground
        alt={t('blurred-background')}
        bannerImg={parentThumbnail}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white-default"></div>

      <div className="mx-auto flex max-w-screen-2xl flex-col items-center justify-center gap-y-24 px-32 pb-40 pt-80">
        <h1 className="z-10 text-center text-heading-md font-semibold text-npl-text-icon-on-light-surface-primary">
          {t('what-you-unlock')}
        </h1>

        <div className="flex w-full flex-col flex-wrap justify-center gap-y-8 sm:flex-row">
          {membershipBenefits.map((benefit, index) => (
            <div
              key={index}
              className="z-10 flex flex-col items-center justify-center gap-y-12 pt-28 sm:w-1/3 lg:w-1/6">
              <div className="mt-auto">
                <Icon
                  name={benefit.name}
                  path={benefit.path}
                  fill="#1B1B1880"
                  width={40}
                  height={40}
                />
              </div>
              <div className="flex-1 text-center text-label-md lg:text-heading-xs">
                {benefit.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MembershipBenefits;
