import React from 'react';
import { getMetadataList } from './utils';
import Link from 'next/link';
import { getFolderItemPreviewLink } from '@/utility/routesHelper';
import { THUMBNAIL } from '@/contexts/LibrarySetupContext/constants';

const FileFolderItem = ({
  item,
  hasAccess,
  ctaOnClick,
  setOpenModal = null,
  communitySlug = '',
  productSlug = ''
}) => {
  const slug = getFolderItemPreviewLink({
    communitySlug,
    productSlug,
    folderItemId: item._id
  });

  return hasAccess ? (
    <Link href={slug}>
      <div className="flex flex-row items-center gap-16 overflow-hidden overflow-ellipsis whitespace-nowrap">
        <div className="relative h-60 max-h-[60px] min-h-[60px] w-[60px] min-w-[60px] max-w-[60px]">
          <img
            alt="pdf file thumbnail"
            className="overflow-hidden rounded-8 border-npl-neutral-light-solid-6 w-full h-full object-cover"
            src={item?.thumbnail ?? THUMBNAIL.file}
          />
        </div>

        <div className="flex w-full max-w-[calc(100%-30px)] flex-col gap-4">
          <div className="w-[640px] max-w-[85%] overflow-hidden overflow-ellipsis whitespace-nowrap text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
            {item?.title}
          </div>
          <div className="flex w-[640px] max-w-[85%] flex-row items-center gap-6  overflow-hidden overflow-ellipsis whitespace-nowrap">
            {getMetadataList(item)
              ?.filter((item) => item !== '' && item)
              ?.map((metadata, index) => {
                return (
                  <div key={index}>
                    <div className="text-label-sm text-npl-text-icon-on-light-surface-secondary">
                      {metadata}
                    </div>
                    <div className="h-4 w-4 rounded-full bg-npl-text-icon-on-light-surface-secondary last:hidden" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <div
      onClick={() => {
        if (setOpenModal) {
          setOpenModal(false);
        }
        ctaOnClick();
      }}
      className="cursor-pointer">
      <div className="flex flex-row items-center gap-16 overflow-hidden overflow-ellipsis whitespace-nowrap">
        <div className="relative h-60 max-h-[60px] min-h-[60px] w-[60px] min-w-[60px] max-w-[60px]">
          <img
            alt="pdf file thumbnail"
            className="overflow-hidden rounded-8 border-npl-neutral-light-solid-6 w-full h-full object-cover"
            src={item?.thumbnail ?? THUMBNAIL.file}
          />
        </div>

        <div className="flex w-full max-w-[calc(100%-30px)] flex-col gap-4">
          <div className="w-[640px] max-w-[85%] overflow-hidden overflow-ellipsis whitespace-nowrap text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
            {item?.title}
          </div>
          <div className="flex w-[640px] max-w-[85%] flex-row items-center gap-x-6  overflow-hidden overflow-ellipsis whitespace-nowrap">
            {getMetadataList(item)
              ?.filter((item) => item !== '' && item)
              ?.map((metadata, index) => {
                return (
                  <div key={index}>
                    <div className="flex gap-x-6 text-label-sm text-npl-text-icon-on-light-surface-secondary">
                      <span>{metadata}</span>
                      <span>
                        {index !== getMetadataList(item).length - 1 && '•'}
                      </span>
                    </div>
                    <div className="h-4 w-4 rounded-full bg-npl-text-icon-on-light-surface-secondary last:hidden" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileFolderItem;
