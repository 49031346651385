import React, { FC } from 'react';

import NextImage from './NextImage';

type Props = { bannerImg: string };

const GradientBlurToBottomBg: FC<Props> = ({ bannerImg }) => {
  return (
    <>
      <div className="bg-gradient-to-b absolute left-0 top-0 z-[-1] h-full w-full from-transparent to-[#FFF] " />
      <div className="absolute left-0 top-0 z-[-1] h-full w-full bg-white-default opacity-[0.95] " />
      <div className="absolute left-0 top-0 z-[-2] h-[412px] w-full">
        <NextImage
          className="h-full w-full blur-2xl"
          mobileImgProps={{
            src: bannerImg,
            quality: 1,
            layout: 'fill',
            height: 412,
            objectFit: 'cover'
          }}
        />
      </div>
    </>
  );
};

export default GradientBlurToBottomBg;
