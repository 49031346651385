import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  getTrackingInformationForBE,
  trackGAEvent
} from '@/utility/analytics';
import { checkIsCommunityCheckoutRequired } from '@/utility/communityHelpers';
import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { getTimezoneId } from '@/utility/dateHelper';
import { checkIfIndexable } from '@/utility/helpers';
import { t } from '@/utility/localization';
import {
  getCommunityCheckoutRoute,
  getDigitalProductAccessPageLink
} from '@/utility/routesHelper';

import {
  getResourceBySlug,
  unifiedCheckoutSignup
} from '@/services/communitiesService';

import { useAuthContext } from '@/contexts/AuthContext';

import BackAndShareBtnBar from '@/components/common/BackAndShareBtnBar/BackAndShareBtnBar';
import GradientBlurToBottomBg from '@/components/common/GradientBlurToBottomBg';
import PageMetaTags from '@/components/common/PageMetaTags';
import { PRICE_TYPES } from '@/components/common/PricingSectionAndDiscounts/constants';
import PublicPageFooter from '@/components/common/PublicPageFooter';
import {
  showErrorToast,
  showSuccessToast
} from '@/components/common/ToastContainer';
import WithPendingCommMembershipApprovalModal from '@/components/features/CommunityPage/components/home/WithPendingCommMembershipApprovalModal';
import GenericNavBar from '@/components/features/Navbar/GenericNavbar';
import NPLButton from '@/components/npl/NPLButton';

import useCommonPublicPageData from '@/hooks/useCommonPublicPageData';
import { DISCOUNT_ENTITY_TYPE } from '@/hooks/useDiscount';
import useEntityPrice from '@/hooks/useEntityPrice';
import usePixelTracking from '@/hooks/usePixelTracking';
import usePosts from '@/hooks/usePosts';
import useQueryParams from '@/hooks/useQueryParams';
import {
  getCheckoutRequestor,
  getUnifiedSignupMemberInfo,
  getUnifiedSignupPaymentProvider
} from '@/hooks/utils/communityMembersUtils';

import { getPriceText } from '@/pages/portal/utils';

// import SignUpPopup from '../SignUpPopup';
import {
  SIGNUP_ORIGIN,
  SIGNUP_REQUESTOR,
  WHATSAPP_SIGNUP_REQUESTOR
} from '../SignUpPopup/constants';
import ContentCard from './components/ContentCard/ContentCard';
import HeroBanner from './components/HeroBanner';
import MembershipBenefits from './components/MembershipBenefits';
import ProductMetaData from './components/ProductMetadata';

const BecomeAnAffiliateCard = dynamic(
  () => import('../Affiliates/components/BecomeAnAffiliateCard')
);
const YoureAnAffiliateOfTheProductCard = dynamic(
  () => import('../Affiliates/components/YoureAnAffiliateOfTheProductCard')
);
const BecomeAnAffiliateModal = dynamic(
  () => import('../Affiliates/components/BecomeAnAffiliateModal')
);
const CommunitySignupModal = dynamic(
  () => import('@/features/CommunitySignupModal/CommunitySignupModal'),
  {
    ssr: false
  }
);

export const RESOURCE_TYPE = {
  DIGITAL_PRODUCT: 'DIGITAL_PRODUCT',
  CLASS: 'class',
  COLLECTION: 'collection',
  VIDEO: 'video',
  FILE: 'file',
  EXTERNAL_LINK: 'external_link'
};

const DEFAULT_IMAGE = `${STATIC_ASSET_BASE_URL}/nasIO/common/png/Image-fixed-aspect-ratio-spacer.png`;

const ResourcePage = (props) => {
  const { currentRouterQuery } = useQueryParams();
  const pageData = props.pageInfo.templateData;
  const resourceSlug = pageData.productSlug;
  const communitySlug = pageData.communitySlug;
  const communityInfo = pageData.communityInfo;
  const templateContentType = props.pageInfo.templateContentType;
  const upcomingEventsCount = communityInfo.upcomingEventsCount;
  const communityId = communityInfo?._id;
  const userCommunitiesData = props?.userCommunitiesData;

  const {
    isCommunityAdmin,
    isPendingApproval,
    isCommunityMember,
    isCommunityAffiliate,
    isSignUpModalOpened,
    openSignUpModal,
    isSignupModalOnlyForCommunity,
    isSignupModalToBecomeAffiliate,
    onSignUpPopupClose,
    loadingUserRoles,
    fetchingUserCommunities,
    isUserCommunitiesFetched,
    mutateUserCommunities,
    // affilaite program
    joinAsAffiliateProps,
    setIsAffiliateProduct
  } = userCommunitiesData || {};

  const commonPublicPageData = useCommonPublicPageData({
    communityInfo
  });

  const {
    pricingData,
    priceTagText,
    discountCodeFromQueryParams,
    affiliateCode
  } = commonPublicPageData;

  const { allPosts } = usePosts({
    communityId: communityId
  });
  // Load pixel if data is available.
  usePixelTracking({
    communityId,
    trackingPixels: pageData?.communityInfo?.trackingPixels,
    postInitProcess: {
      trackPageView: true
    }
  });

  const router = useRouter();
  const pathname = usePathname();

  const [loadingResourceOnClient, setLoadingResourceOnClient] =
    useState(false);
  const [clientResourceInfo, setClientResourceInfo] = useState(null);

  const { isLoggedIn, user } = useAuthContext();

  const isCommunityCheckoutRequired =
    !isCommunityMember && checkIsCommunityCheckoutRequired(communityInfo);

  const staticResourceInfo = pageData.resourceInfo;
  const resourceInfo = clientResourceInfo ?? staticResourceInfo;
  const type = resourceInfo.type;
  const isFolderEmpty = !resourceInfo?.folderItems?.length;

  // Affiliate Program Data start
  const isAffiliateProduct = Boolean(
    resourceInfo?.affiliateInfo?.isAffiliateProduct
  );

  const {
    // card
    showJoinAsAffiliateCard,

    // modal
    showJoinAsAffiliateModal,
    onOpenJoinAsAffiliateModal,
    onCloseJoinAsAffiliateModal,

    // join as affiliate fn
    isSubmittingJoinAsAffiliate,
    onJoinAsAffiliate
  } = joinAsAffiliateProps;

  const showYouAreAnAffiliateCard =
    isCommunityAffiliate && isAffiliateProduct;

  useEffect(() => {
    setIsAffiliateProduct(isAffiliateProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAffiliateProduct]);
  // Affiliate Program Data end

  const isResourceCheckoutRequired =
    resourceInfo?.access === 'paid' && !resourceInfo?.isPurchased;

  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';

  const metaTagProps = {
    title: staticResourceInfo.title,
    description: staticResourceInfo.description,
    url: `${origin}${pathname}`,
    imageUrl:
      staticResourceInfo?.thumbnail ??
      staticResourceInfo?.video?.thumbnailLink ??
      DEFAULT_IMAGE
  };

  const goToAccessDigitalProductPage = () => {
    const productAccessPageLink = getDigitalProductAccessPageLink({
      communitySlug: communitySlug,
      productSlug: resourceSlug
    });
    router.push(productAccessPageLink);
  };

  const redirectToCheckout = useCallback(
    (params = {}) => {
      const {
        ignoreFolderPurchase = false,
        isForAffiliateSignup = false
      } = params;

      const newLongUrl = getCommunityCheckoutRoute({
        communityId: communityInfo?._id,
        communityCode: communityInfo?.code,
        requestor: communityInfo?.isWhatsappExperienceCommunity
          ? WHATSAPP_SIGNUP_REQUESTOR
          : SIGNUP_REQUESTOR,
        sourceInfo: ignoreFolderPurchase
          ? {}
          : {
              type: 'folder',
              origin: resourceInfo._id
            },
        entityDiscountCode: discountCodeFromQueryParams,
        affiliateCode,
        withJoinAsAffiliateCb: Boolean(isForAffiliateSignup)
      });

      window.location.href = newLongUrl;
    },
    [
      communityInfo?._id,
      communityInfo?.code,
      communityInfo?.isWhatsappExperienceCommunity,
      discountCodeFromQueryParams,
      resourceInfo._id,
      affiliateCode
    ]
  );

  const { discountedCodeData } = useEntityPrice({
    communityCode: communityInfo?.code,
    entityId: resourceInfo?._id,
    entityType: DISCOUNT_ENTITY_TYPE.FOLDER
  });

  const currency =
    resourceInfo?.priceDetails?.localCurrency ||
    resourceInfo?.priceDetails?.currency ||
    'USD';

  const contentPrice =
    resourceInfo?.access === 'paid'
      ? (resourceInfo?.priceDetails?.localAmount ??
        resourceInfo?.priceDetails?.amount)
      : 0;

  const discountedResourcePrice = useMemo(() => {
    if (discountedCodeData) {
      const { type, value } = discountedCodeData;
      if (type === 'percentage') {
        return parseFloat(
          Number(contentPrice * (1 - value / 100)).toFixed(2)
        );
      }
    }

    return null;
  }, [discountedCodeData, contentPrice]);

  const formattedResourcePriceText = (() => {
    if (resourceInfo?.access === 'free' || contentPrice === 0) return '';

    return getPriceText(contentPrice, discountedResourcePrice, currency);
  })();

  const onSignUp = async () => {
    if (resourceInfo.isPurchased) {
      goToAccessDigitalProductPage();
      return;
    }

    if (isCommunityCheckoutRequired || isResourceCheckoutRequired) {
      redirectToCheckout();
      return;
    }

    openSignUpModal();

    trackGAEvent('public_library_page_signup', {
      folderId: clientResourceInfo?._id
    });
  };

  const isSoldOut = resourceInfo?.isSoldOut;

  const getCtaProps = () => {
    const ctaLoading =
      loadingResourceOnClient ||
      loadingUserRoles ||
      fetchingUserCommunities;

    if (isCommunityAdmin) {
      return {
        ctaText: t('access'),
        ctaDisabled: false,
        ctaOnClick: goToAccessDigitalProductPage,
        ctaLoading
      };
    }

    if (isLoggedIn && isCommunityMember) {
      if (
        resourceInfo?.access === 'free' ||
        (resourceInfo?.access === 'paid' && resourceInfo.isPurchased)
      ) {
        return {
          ctaText: t('access'),
          ctaDisabled: false,
          ctaOnClick: goToAccessDigitalProductPage,
          ctaLoading
        };
      }
    }

    if (isSoldOut) {
      return {
        ctaText: t('sold-out'),
        ctaDisabled: true,
        ctaOnClick: () => {}
      };
    }

    if (isLoggedIn && isPendingApproval) {
      return {
        ctaText: t('pending-approval'),
        ctaDisabled: true,
        ctaOnClick: () => {},
        ctaLoading
      };
    }

    if (resourceInfo?.pricingConfig?.priceType === PRICE_TYPES.FLEXIBLE) {
      return {
        ctaText: t('pay-what-you-want'),
        ctaDisabled: false,
        ctaOnClick: onSignUp,
        ctaLoading
      };
    }

    return {
      ctaText:
        resourceInfo?.access === 'paid' ? t('buy-now') : t('get-for-free'),
      ctaDisabled: false,
      ctaOnClick: onSignUp,
      ctaLoading
    };
  };

  const ctaProps = getCtaProps();

  const getAccessToContent = () => {
    if (isLoggedIn) {
      if (isCommunityMember) {
        if (resourceInfo?.access === 'paid') {
          return resourceInfo.isPurchased ? true : false;
        }
        return true;
      }
    }
    return false;
  };

  const getCardProps = ({ type }) => {
    const basicInfo = {
      title: resourceInfo.title,
      communityInfo,
      folderItems: resourceInfo.folderItems,
      description: resourceInfo.description,
      ...ctaProps,
      showViews: resourceInfo?.showViews || false,
      folderViewCount: resourceInfo.folderViewCount,
      priceText:
        resourceInfo?.pricingConfig?.priceType === PRICE_TYPES.FLEXIBLE
          ? null
          : formattedResourcePriceText,
      isPurchased: resourceInfo.isPurchased,
      pricingConfig: resourceInfo?.pricingConfig,
      isCommunityAdmin: isCommunityAdmin
    };

    switch (type) {
      case RESOURCE_TYPE.CLASS: {
        return {
          ...basicInfo,
          communitySlug: communitySlug,
          thumbnail: resourceInfo.thumbnail ?? DEFAULT_IMAGE
        };
      }
      case RESOURCE_TYPE.DIGITAL_PRODUCT: {
        return {
          ...basicInfo,
          communitySlug: communitySlug,
          thumbnail: resourceInfo.thumbnail ?? DEFAULT_IMAGE,
          isPurchased: resourceInfo.isPurchased
        };
      }
      case RESOURCE_TYPE.COLLECTION: {
        return {
          ...basicInfo,
          emoji: resourceInfo.emoji
        };
      }
      case RESOURCE_TYPE.VIDEO: {
        return {
          ...basicInfo,
          thumbnail: resourceInfo?.video?.thumbnailLink ?? DEFAULT_IMAGE,
          duration: resourceInfo?.video?.duration
        };
      }
      case RESOURCE_TYPE.FILE: {
        return {
          ...basicInfo
        };
      }
      case RESOURCE_TYPE.EXTERNAL_LINK: {
        return {
          ...basicInfo
        };
      }
    }
  };

  const showMembershipBenefits = !isCommunityMember;

  const getClientResourceInfo = useCallback(async () => {
    try {
      setLoadingResourceOnClient(true);

      const communitySlugWithoutSlash = communitySlug.replace(/\//g, '');
      const { data, error } = await getResourceBySlug(
        `${communitySlugWithoutSlash}/${resourceSlug}`
      );
      if (error) throw new Error(error);

      setClientResourceInfo(data?.resourceInfo);
      setLoadingResourceOnClient(false);
    } catch (e) {
      console.error(e.message);
      setLoadingResourceOnClient(false);
    }
  }, [communitySlug, resourceSlug]);

  const handleFreeCommunitySignup = async (params = {}) => {
    const { isForAffiliateSignup } = params;

    if (isLoggedIn) {
      let payload = {
        communityCode: communityInfo?.code,
        timezone: getTimezoneId(),
        trackingData: getTrackingInformationForBE(),
        requestor: getCheckoutRequestor({ communityInfo }),
        paymentProvider: getUnifiedSignupPaymentProvider(communityInfo),
        memberInfo: getUnifiedSignupMemberInfo(user),
        items: [
          {
            type: 'SUBSCRIPTION'
          }
        ]
      };

      if (currentRouterQuery.affiliateCode) {
        payload.affiliateCode = currentRouterQuery.affiliateCode;
      }

      const { data, error } = await unifiedCheckoutSignup(payload);

      if (error) {
        showErrorToast(error);
        return;
      }

      // const bookingId = data?.enrollmentStatus?.purchaseTransactionObjectId;

      if (data?.enrollmentStatus?.memberEnrolled) {
        showSuccessToast(t('successfully-joined-community'));
        getClientResourceInfo();
        mutateUserCommunities();

        if (isForAffiliateSignup) {
          onOpenJoinAsAffiliateModal();
        }
      }

      return;
    }

    openSignUpModal({
      onlySignupToCommunity: true,
      isSignupModalToBecomeAffiliate: Boolean(isForAffiliateSignup)
    });
  };

  // Used when logged in user wants to join the community for affiliate without purcharing product
  const handleJoinAsMember = (params = {}) => {
    const { isForAffiliateSignup } = params;
    if (isCommunityCheckoutRequired) {
      // go to checkout
      redirectToCheckout({
        ignoreFolderPurchase: true,
        isForAffiliateSignup: Boolean(isForAffiliateSignup)
      });
      return;
    }

    handleFreeCommunitySignup({
      isForAffiliateSignup
    });
  };

  const isIndexable =
    checkIfIndexable(communityInfo) && checkIfIndexable(resourceInfo);

  const ChatGroupIcons = {
    whatsapp: {
      iconName: 'whatsapp-neutral',
      chatGroupName: t('whatsapp-group')
    },
    telegram: {
      iconName: 'telegram-neutral',
      chatGroupName: t('telegram-group')
    },
    facebook: {
      iconName: 'facebook-neutral',
      chatGroupName: t('facebook-group')
    },
    discord: {
      iconName: 'discord-neutral',
      chatGroupName: t('discord-server')
    },
    slack: {
      iconName: 'slack-neutral',
      chatGroupName: t('slack-channel')
    },
    linkedin: {
      iconName: 'linkedin-neutral',
      chatGroupName: t('linkedin-group')
    }
  };

  const getMembershipBenefits = () => {
    const membershipBenefits = [
      {
        name: 'users-01',
        path: 'common',
        title: t('community-access')
      },
      {
        name: 'announcement-03',
        path: 'common',
        title: t('new-updates')
      }
    ];
    if (allPosts.length) {
      membershipBenefits.push({
        name: 'layout-alt-03',
        path: 'common',
        title: `${allPosts.length} ${
          allPosts.length > 1 ? t('posts') : t('post')
        }`
      });
    }
    if (!isFolderEmpty) {
      membershipBenefits.push({
        name: 'video-courses',
        path: 'common',
        title: t('exclusive-content')
      });
    }
    if (upcomingEventsCount) {
      membershipBenefits.push({
        name: 'calendar',
        path: 'common',
        title: `${upcomingEventsCount} ${t('events')}`
      });
    }
    if (communityInfo.platforms?.length > 0) {
      const platform = communityInfo.platforms[0].name;
      membershipBenefits.push({
        name: ChatGroupIcons[platform]?.iconName,
        path: 'social',
        title: ChatGroupIcons[platform]?.chatGroupName
      });
    }

    return membershipBenefits;
  };

  const membershipBenefits = getMembershipBenefits();
  const hasAccess = getAccessToContent();

  useEffect(() => {
    getClientResourceInfo();
  }, [getClientResourceInfo]);

  // Page view analytics
  useEffect(() => {
    if (clientResourceInfo?._id) {
      trackGAEvent('library_folder_public_page_view', {
        folderId: clientResourceInfo._id,
        communityCode: communityInfo?.code,
        communityId: communityInfo?._id
      });
    }
  }, [clientResourceInfo, communityInfo?._id, communityInfo?.code]);

  const isFlexiblePriceType =
    resourceInfo?.pricingConfig?.priceType === PRICE_TYPES.FLEXIBLE;

  return (
    <>
      <PageMetaTags {...metaTagProps} indexable={isIndexable} />

      {isUserCommunitiesFetched && !isLoggedIn && (
        <GenericNavBar
          communityInfo={communityInfo}
          openSignUpModal={openSignUpModal}
        />
      )}
      <div className="m-auto flex h-screen max-w-[1200px] flex-col font-poppins">
        <div className="py-16">
          <BackAndShareBtnBar
            entityTitle={resourceInfo?.title}
            entityType="product"
            communityImageSrc={communityInfo?.profileImage}
            communityLink={communitySlug}
            communityTitle={communityInfo?.title}
            isLoggedIn={isLoggedIn}
          />
        </div>
        <div className="fixed bottom-0 z-20 border border-t-1 border-npl-neutral-light-solid-4 flex w-full gap-x-16 bg-npl-base-white py-12 px-16 sm:hidden">
          {!isFlexiblePriceType && (
            <span className="flex w-[30%] items-center justify-center text-center text-heading-xs font-medium text-npl-text-icon-on-light-surface-primary">
              {formattedResourcePriceText}
            </span>
          )}

          <div className="w-full">
            <NPLButton
              leadIcon=""
              size="xl"
              buttonText={ctaProps.ctaText}
              onClick={ctaProps.ctaOnClick}
              disabled={ctaProps.ctaDisabled}
              loading={ctaProps.ctaLoading}
              stretch={true}
              hierarchy="accent_primary"
            />
          </div>
        </div>
        {/* background image */}
        <GradientBlurToBottomBg
          bannerImg={resourceInfo.thumbnail ?? DEFAULT_IMAGE}
        />

        <div className="h-full flex-1">
          <div className="flex w-full justify-center pb-24">
            <div className="group relative w-full flex-grow">
              <div className="relative mx-12 sm:pb-80 lg:mx-auto lg:flex lg:space-x-[30px]">
                {/* left */}
                <div className="h-fit w-full min-w-40">
                  <div className="space-y-28">
                    <HeroBanner
                      thumbnail={resourceInfo.thumbnail ?? DEFAULT_IMAGE}
                      folderItems={resourceInfo.folderItems}
                    />
                    {/* mobile metadata section */}
                    <div className="lg:hidden">
                      <ProductMetaData {...getCardProps({ type })} />
                    </div>
                    <div>
                      {!isFolderEmpty ? (
                        <ContentCard
                          hasAccess={hasAccess}
                          ctaText={ctaProps.ctaText}
                          ctaOnClick={ctaProps.ctaOnClick}
                          folderItems={resourceInfo.folderItems}
                          communitySlug={communitySlug}
                          productSlug={resourceSlug}
                        />
                      ) : null}
                    </div>
                  </div>

                  {showJoinAsAffiliateCard && (
                    <div className="affiliate-card-section my-32 md:mx-auto md:my-40 md:max-w-[880px]">
                      <BecomeAnAffiliateCard
                        isCommunityMember={isCommunityMember}
                        onClick={onOpenJoinAsAffiliateModal}
                        communityId={communityId}
                        entityId={resourceInfo?._id}
                        entityType="FOLDER"
                        affiliateCode={affiliateCode}
                      />
                    </div>
                  )}
                  {showYouAreAnAffiliateCard && (
                    <div className="affiliate-card-section my-32 md:mx-auto md:my-40 md:max-w-[880px]">
                      <YoureAnAffiliateOfTheProductCard />
                    </div>
                  )}
                </div>
                {/* right */}
                <div className="hidden w-full lg:block lg:max-w-[720px]">
                  <div className="sticky top-48">
                    <ProductMetaData {...getCardProps({ type })} />
                  </div>
                </div>
              </div>
              {showMembershipBenefits && (
                <MembershipBenefits
                  parentThumbnail={
                    resourceInfo?.parentThumbnail ?? DEFAULT_IMAGE
                  }
                  membershipBenefits={membershipBenefits}
                />
              )}

              <PublicPageFooter
                templateContentType={templateContentType}
                isCommunityAdmin={isCommunityAdmin}
                communitySlug={communitySlug}
              />
            </div>
          </div>
        </div>
      </div>

      <CommunitySignupModal
        open={isSignUpModalOpened}
        onClose={onSignUpPopupClose}
        communityInfo={{ ...communityInfo, pricingData }}
        priceTagText={priceTagText}
        discountCodeFromQueryParams={discountCodeFromQueryParams}
        isSignupModalToBecomeAffiliate={isSignupModalToBecomeAffiliate}
        signUpOriginProps={
          isSignupModalOnlyForCommunity
            ? {}
            : {
                isPaid: staticResourceInfo?.access === 'paid',
                resourceInfo: staticResourceInfo,
                signUpOrigin: SIGNUP_ORIGIN.RESOURCE_PAGE
              }
        }
        newMemberSignupCallback={(isPending) => {
          mutateUserCommunities();

          if (isPending) {
            return;
          }

          // Do nothing if signup was only for community
          if (isSignupModalOnlyForCommunity) {
            // if signup was for affiliate, redirect to affiliate products page
            if (isSignupModalToBecomeAffiliate) {
              onOpenJoinAsAffiliateModal();
            }
            return;
          }

          // if is Paid, go to checkout
          // else go to access digital product page

          if (staticResourceInfo?.access === 'paid') {
            redirectToCheckout();
            return;
          }

          goToAccessDigitalProductPage();
        }}
        existingMemberLoginCallback={async () => {
          mutateUserCommunities();

          const resourceInfo = await getClientResourceInfo();

          // if signup was for affiliate, redirect to affiliate products page
          if (isSignupModalToBecomeAffiliate) {
            onOpenJoinAsAffiliateModal();
          }

          // if paid
          if (staticResourceInfo?.access === 'paid') {
            if (resourceInfo?.isPurchased) {
              goToAccessDigitalProductPage();
            }
            return;
          }
        }}
      />

      <WithPendingCommMembershipApprovalModal />

      {showJoinAsAffiliateModal && (
        <BecomeAnAffiliateModal
          open={showJoinAsAffiliateModal}
          onClose={onCloseJoinAsAffiliateModal}
          isLoggedIn={isLoggedIn}
          userProfileImgSrc={user?.learner?.profileImage}
          communityId={communityId}
          communityTitle={communityInfo?.title}
          communityProfileImgSrc={communityInfo?.profileImage}
          isCommunityMember={isCommunityMember}
          onJoinCommunity={() =>
            handleJoinAsMember({
              isForAffiliateSignup: true
            })
          }
          onJoinAsAffiliate={onJoinAsAffiliate}
          isSubmittingJoinAsAffiliate={isSubmittingJoinAsAffiliate}
        />
      )}
    </>
  );
};

export default ResourcePage;
