import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';
import { t } from '@/utility/localization';
import { ReadMoreModal } from '@/components/common/ReadMoreModal/ReadMoreModal';
import LinkAwareText from '@/components/common/LinkAwareText';
import classNames from 'classnames';
import type { PricingConfigType } from '@/components/common/PortalEntityPriceDisplayTag';

type folderItems = {
  description: string;
  format: string;
  index: number;
  size: string;
  status: string;
  thumbnail: string;
  title: string;
  type: string;
  _id: string;
  items?: folderItems[];
};
type ProductMetaDataProps = {
  title: string;
  communityInfo: {
    title: string;
    profileImage: string;
  };
  thumbnail: string;
  folderItems: folderItems[];
  description: string;
  ctaOnClick: () => void;
  ctaLoading: boolean;
  ctaText: string;
  folderViewCount: number;
  showViews: boolean;
  priceText: string;
  communitySlug: string;
  ctaDisabled: boolean;
  isPurchased: boolean;
  pricingConfig: PricingConfigType;
  isCommunityAdmin: boolean;
};

const ProductMetaData = ({
  title,
  folderItems,
  description,
  ctaOnClick,
  ctaLoading,
  ctaText,
  folderViewCount,
  showViews,
  priceText,
  ctaDisabled,
  isPurchased,
  isCommunityAdmin
}: ProductMetaDataProps) => {
  // count number of sections from folderItems.type
  const sectionCount = folderItems?.filter(
    (item) => item.type === 'section'
  )?.length;
  const itemCount = folderItems.length;

  return (
    <div className="col-span-1 p-24 pb-80">
      {/* title, price and cta */}
      <div className="space-y-16 border-b border-npl-transparent-black-10 pb-32">
        <h1 className="text-display-xs font-semibold text-npl-text-icon-on-light-surface-primary">
          {title}
        </h1>
        <div className="hidden space-y-16 sm:block">
          {!isPurchased && !isCommunityAdmin && (
            <div className="text-heading-md font-medium text-npl-text-icon-on-light-surface-primary">
              {priceText}
            </div>
          )}
          <NPLButton
            leadIcon=""
            size="xl"
            buttonText={ctaText}
            onClick={ctaOnClick}
            disabled={ctaDisabled}
            loading={ctaLoading}
            stretch={true}
            hierarchy="accent_primary"
          />
        </div>
      </div>
      {/* metadata */}
      <div
        className={classNames(
          `flex justify-between space-x-2  border-b border-npl-transparent-black-10 py-32`
        )}>
        {sectionCount !== 0 && (
          <div className="flex w-full items-center justify-start gap-x-4 sm:gap-x-8">
            <Icon name="git-branch-01" className="h-20 w-20" />
            <span>
              {sectionCount} {t('sections')}
            </span>
          </div>
        )}
        <div className="flex w-full items-center justify-start gap-x-4 sm:gap-x-8">
          <Icon name="file-01" className="h-20 w-20" />
          <span>
            {itemCount} {t('Items')}
          </span>
        </div>
        {folderViewCount !== 0 && showViews && (
          <div className="flex w-full items-center justify-start gap-x-4 sm:gap-x-8">
            <Icon name="eye" className="h-20 w-20" />
            <span>
              {folderViewCount} {t('views')}
            </span>
          </div>
        )}
      </div>
      {/* description */}
      {description && (
        <div className="py-32">
          <h1 className="text-label-lg font-semibold text-npl-text-icon-on-light-surface-primary">
            {t('description')}
          </h1>
          <ReadMoreModal
            ctaText={ctaText}
            ctaOnClick={ctaOnClick}
            label={t('more2')}
            maxHeight={120}
            buttonStyling="text-npl-yellow-light-solid-11 text-label-lg font-semibold"
            className="mt-8 whitespace-pre-line break-words text-body-xs font-normal text-dark-1b">
            <div className="text-body-md">
              <LinkAwareText text={description} />
            </div>
          </ReadMoreModal>
        </div>
      )}
    </div>
  );
};

export default ProductMetaData;
